import React, { useEffect, useState } from 'react'
import gsap from 'gsap'
import { graphql } from 'gatsby'
import PartnersMarquee from 'embeds/partners-marquee'
import Testimonials from 'embeds/testimonials'

export default function Marquee({ data }) {
	const logos = data.prismicHomePage.data.client_logos.map(({ logo }) => logo)
	const [direction, setDirection] = useState(1)

	useEffect(() => {
		if (direction > 0) {
			gsap.getById('partners-marquee-backward-tl').pause()
			gsap.getById('partners-marquee-forward-tl').play()
		} else {
			gsap.getById('partners-marquee-forward-tl').pause()
			gsap.getById('partners-marquee-backward-tl').play()
		}
	}, [direction])

	return (
		<div className="h-screen w-full overflow-hidden bg-gray-200 pt-64">
			<Testimonials />

			<div className="h-20" />

			<PartnersMarquee logos={logos} />

			<button
				className="mt-20"
				onClick={() => setDirection((current) => current * -1)}
			>
				Switch direction
			</button>
		</div>
	)
}

export const query = graphql`
	query marqueeQuery {
		prismicHomePage {
			data {
				client_logos {
					logo {
						url
						dimensions {
							height
							width
						}
					}
				}
			}
		}
	}
`
